<template>
	<div>
		<div class="cation">
			<div class="cation_wrap">
				<div class="cation_head clearfloat">
					<div class="global-search-single">
						<div class="single-left">
							<el-button type="primary" @click="argumentadd()" v-if="hasPerm(['goods.param.store'])">+创建商品参数</el-button>
						</div>
						<div class="single-right">
							<div class="search-item">
								<label class="search-label">参数名称:</label>
								<el-input type="text" placeholder="请输入内容" v-model="note_name" ref="catsearchtext" />
							</div>
							<el-button type="primary" @click="catsearch()">搜索</el-button>
						</div>
					</div>
				</div>
				<div class="cation_container">
					<div class="goods_table">
						<el-table :data="tableData" style="width: 100%">
							<el-table-column prop="id" label="ID"></el-table-column>
							<el-table-column prop="cat_attr_name" label="标题"></el-table-column>
							<el-table-column prop="cat_name" label="分类名称">
							</el-table-column>
							<el-table-column prop="created_at" label="创建时间">
							</el-table-column>
							<el-table-column label="操作" fixed="right" width="200">
								<template slot-scope="scope">
									<div class="caozuo">
										<button @click="argumentedit(scope.row.id)"
											v-if="hasPerm(['goods.param.update'])">编辑</button>
										<button @click="delectcat(scope.row.id)"
											v-if="hasPerm(['goods.param.delete'])">删除</button>
									</div>
								</template>
							</el-table-column>
							<template slot="empty">
								<div class="empty_data">
									<p>暂无数据</p>
								</div>
							</template>
						</el-table>
					</div>
					<div class="table_foot">
						<div class="foot_left">
						</div>
						<div class="main_pages">
							<el-pagination @size-change="handleSizeChange" hide-on-single-page
								@current-change="handleCurrentChange" :current-page.sync="currentPage"
								:page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
								layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
							</el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			note_name: '', //搜索内容
			tableData: [], //表格数据
			currentPage: 1,
			eachPage: 10,
			total_entry: 0,
		}
	},
	created() {
	},
	mounted() {
		this.argumentslistapi();
	},
	methods: {

		argumentslistapi() {
			let data = {
				search: [{
					key: "cat_attr_name",
					value: this.note_name,
					op: "search"
				}],
				order: [{
					key: "id",
					value: "desc"
				}],
				page: this.currentPage,
				limit: this.eachPage
			};
			this.$get(this.$apis.argumentsList, data).then(res => {
				if (res.code == 200) {
					// //console.log(res)
					this.tableData = res.data.list;
					this.total_entry = res.data.total;
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
		argumentadd() {
			this.$router.push({
				name: "argument_edit",
				params: {
					id: 0
				}
			});
		},
		argumentedit(id) {
			this.$router.push({
				name: "argument_edit",
				params: {
					id: id
				}
			});
		},
		handleSizeChange(val) {
			this.eachPage = val;
			this.argumentslistapi();
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.common.backTop();
			this.argumentslistapi();
		},
		// 搜索
		catsearch() {
			let searchtext = this.$refs.catsearchtext.value;
			this.note_name = searchtext;
			this.currentPage = 1;
			this.argumentslistapi();
		},
		// 删除
		delectcat(id) {
			this.$delete(this.$apis.argumentDelete + id).then(res => {
				if (res.code == 200) {
					this.$message({
						type: 'success',
						duration: 1500,
						message: '删除成功',
						onClose: () => {
							this.argumentslistapi();
						}
					});
				} else {
					this.common.message(this, res.message);
				}
			}).catch(err => {
				// //console.log(err)
				this.common.message(this, err.message);
			});
		},
	},
};
</script>

<style scoped>
.goods-label {
	color: #666;
}

.cation_wrap {
	padding: 20px;
	background-color: #fff;
}

.cation_head {
	width: 100%;
	padding-bottom: 20px;
	overflow: hidden;
	margin-bottom: 20px;
	box-shadow: inset 0 -1px 0 #efefef;
}



.table_search-input .el-input {
	width: 270px;
}

.table_search-input .el-input__inner {
	border-radius: 4px 0 0 4px;
	height: 38px;
	width: 100%;
}

.goods_table table th,
table td {
	border: none;
}

.goods_table .el-table .el-table__header-wrapper .el-table__header thead tr th {
	background-color: #f9f9fc;
}

.goods_table .el-table .el-table__header-wrapper {
	background-color: #f9f9fc;
}

.goods_table .el-table .el-table__fixed .el-table__fixed-header-wrapper {
	background-color: #f9f9fc;
}

.el-table th.el-table__cell {
	background-color: #f9f9fc;
	font-weight: 600;
	color: #333;
	padding: 8px 0;
}

.caozuo button {
	padding: 0 10px;
	border: 0;
	background: transparent;
	border-right: 1px solid var(--fontColor, #fb6638);
	cursor: pointer;
	display: inline-block;
	line-height: 1;
	color: var(--fontColor);
}

.caozuo button:last-child {
	border-right: none;
}

.empty_data {
	width: 100%;
	padding: 10px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

}

.empty_data p {
	margin-top: 16px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: rgba(0, 0, 0, 0.4);
	line-height: 20px;
}


.table_foot {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.main_pages {
	display: flex;
	align-items: center;
}

.main_pages .el-pagination {
	display: flex;
	align-items: center;
}

.main_pages .el-pagination .el-pager {
	display: flex;
	align-items: center;
}

.main_pages .el-pagination .el-pager .number {
	min-width: 32px;
	height: 32px !important;
	line-height: 32px !important;
	background: #ffffff;
	border-radius: 4px;
	border: 1px solid #dcdee0;
	margin-left: 4px;
}

.main_pages .el-pagination .el-pager .number.active {
	color: #fff !important;
	background: var(--fontColor);
	border-color: var(--fontColor, #fb6638) !important;
}

.main_pages .el-pagination .el-pager .number:first-child {
	margin-left: 0;
}
</style>

<style lang="scss" scoped>
.table_search-input{
  /deep/.el-input{
    .el-input__inner{
      height: 40px;
      line-height: 40px;
    }
  }
  button{
    height: 40px;
    line-height: 40px;
  }
}
</style>